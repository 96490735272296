import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Typography } from "@mui/material";

type Props = {
  text?: string;
  color?: string;
  backgroundColor?: string;
  size?: number;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  hideIcon?: boolean;
  onPress?: () => void;
};

function ProgressBadge({
  text = "",
  color = "#FFFEEE",
  backgroundColor,
  size = 150,
  style,
  children,
  hideIcon = false,
  onPress,
}: Props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        ...style,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: backgroundColor,
          width: `${size}px`,
          height: `${size}px`,
          borderRadius: `${size / 2}px`,
          borderColor: color,
          borderStyle: "solid",
          borderWidth: "6px",
          padding: 4,
          color: color,
          fontSize: `${size / 3}px`,
          fontWeight: 500,
          textAlign: "center",
          cursor: onPress ? "pointer" : "default",
          // add shadow
          boxShadow: "10px 10px 20px rgba(0, 0, 0, 0.5)",
        }}
        onClick={() => onPress && onPress()}
      >
        {text}
      </div>
      <Typography
        variant="h6"
        paddingTop={2}
        paddingLeft={6}
        paddingRight={6}
        textAlign={"center"}
      >
        {children}
      </Typography>
      {!hideIcon && (
        <ArrowDropDownIcon style={{ fontSize: 50, color: color }} />
      )}
    </div>
  );
}

export default ProgressBadge;
