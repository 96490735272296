import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import header from "../assets/images/banner/b013.jpg";
import ParagraphTitle from "../components/paragraphTitle/ParagraphTitle";

export const About = () => {
  return (
    <>
      <CssBaseline />
      <Container
        sx={{
          backgroundColor: "white",
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          overflow: "hidden",
        }}
        disableGutters
      >
        <Box
          sx={{
            backgroundImage: `url(${header})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            aspectRatio: "300/200",
            paddingTop: "72px",
            textAlign: "center",
            padding: 0,
          }}
        />
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            paddingTop: { xs: 2, sm: 2, md: 8 },
            paddingX: { xs: 2, sm: 2, md: 12 },
          }}
        >
          <Typography variant="h2" gutterBottom>
            Om oss
          </Typography>

          <Typography variant="h3" gutterBottom>
            Vem är vi?
          </Typography>

          <Typography
            variant="body1"
            // paddingTop={6}
            // paddingLeft={6}
            // paddingRight={6}
            gutterBottom
          >
            Flavourado AB är ett nystartat svenskt livsmedelsföretag med
            specialisering inom import av vanilj från Madagaskar till den
            svenska marknaden. Vårt företag har etablerat starka partnerskap med
            pålitliga leverantörer som direkt samarbetar med vanilj odlarna,
            vilket säkerställer en hög nivå av kvalitet.
          </Typography>
          <Typography
            variant="body1"
            // paddingTop={6}
            // paddingLeft={6}
            // paddingRight={6}
            gutterBottom
          >
            Vi strävar efter att erbjuda våra kunder en smakupplevelse genom att
            tillhandahålla de bästa vaniljprodukter som skiljer sig från de
            vanliga alternativen som finns i vanliga mataffärer. Vår dedikation
            till kvalitet till bra priser gör oss till det självklara valet för
            dem som söker att samla in pengar till klassen eller laget.
          </Typography>

          <Typography variant="h3" gutterBottom>
            Varför Vanilj?
          </Typography>

          <Typography
            variant="body1"
            // paddingTop={6}
            // paddingLeft={6}
            // paddingRight={6}
            gutterBottom
          >
            <p>
              Idén om vanilj kom efter att Gustave hade kommit hem från en resa
              i Mauritius med vanilj som han tog med till vänner och familj. Léo
              fick några stänger och tänkte att det var en bra idé att sälja dem
              eftersom mycket av vaniljen som säljs i mataffärer är dyr och
              torr.
            </p>
            <p>
              Sedan under sommarlovet 2023 började vi utveckla idéen om att
              sälja vanilj i Sverige och senare tänkte vi att vi kunde hjälpa
              klasserna genom att de sålde våra produkter.
            </p>
            <p>
              Det är så vi grundade livsmedelsföretaget Flavourado i september
              2023.
            </p>
            <p>
              Vi har själva sålt flera olika produkter under våra skolgång som
              till exempelvis kakor, strumpor och ljus. Och två problem uppstod:
              <ul>
                <li>
                  Vi ansåg att produkterna vara ointressanta eftersom
                  föräldrarna köpte dem för att hjälpa oss utan att ha ett
                  riktigt intresse i produkten vilket kan göra det svårt att
                  sälja.
                </li>
                <li>Vi tjänade inte tillräckligt som klass av det vi sålde.</li>
              </ul>
            </p>
            <p>
              För att lösa dessa problem så erbjuder vi klasser och idrottslag
              att sälja vanilj, en kvalitativ produkt som faktiskt kommer till
              användning, för ett bra pris. Och där klasser tjänar 45% av det de
              säljer.
            </p>
            <p>
              Vanilj är en lyxig ingrediens som inte är överdrivet dyr och som
              kan användas till all möjlig matlagning.
            </p>
            <p>
              Vårt mål är att så simpelt och effektivt som möjligt hjälpa
              klasser och lag att samla in pengar.
            </p>
            <p>Vem gillar inte vanilj?</p>
          </Typography>
        </Box>
      </Container>
    </>
  );

  // return (
  //   <>
  //     <CssBaseline />
  //     <Container fixed style={{ backgroundColor: "white" }}>
  //       <Box sx={{ width: "100%", height: "100vh", padding: "8px" }}>
  //         <Typography variant="h2" gutterBottom>
  //           Om oss
  //         </Typography>
  //         <Typography variant="body1" gutterBottom>
  //           Flavourado AB är ett nystartat svenskt livsmedelsföretag med
  //           specialisering inom import av vanilj från Madagaskar till den
  //           svenska marknaden. Vårt företag har etablerat starka partnerskap med
  //           pålitliga leverantörer som direkt samarbetar med vaniljodlarna,
  //           vilket säkerställer en hög nivå av kvalitet.
  //         </Typography>
  //         <Typography variant="body1" gutterBottom>
  //           Vi strävar efter att erbjuda våra kunder en smak upplevelse genom
  //           att tillhandahålla de bästa vaniljprodukter som skiljer sig från de
  //           vanliga alternativen som finns i vanliga mataffärer. Vår dedikation
  //           till kvalitet till bra priser gör oss till det självklara valet för
  //           dem som söker att sammla in pengar till klassen eller laget.
  //         </Typography>
  //       </Box>
  //     </Container>
  //   </>
  // );
};
