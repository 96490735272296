import React, { useMemo } from "react";
import { Outlet } from "react-router-dom";
import { MenuBar } from "../components/menu";
import Footer from "../components/footer/Footer";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Container } from "@mui/material";
import { flavouradoTheme } from "../theme/flavouradoTheme";
import logo from "../assets/images/logo/logov2.png";
import pattern from "../assets/images/logo/pattern.png";

export const Root = () => {
  // const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const prefersDarkMode = false;

  const theme = useMemo(
    () => createTheme(flavouradoTheme(prefersDarkMode)),
    [prefersDarkMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          minHeight: "100vh",
        }}
      >
        <MenuBar />
        <Container disableGutters>
          <img
            src={logo}
            alt="logo"
            style={{
              width: "100%",
              margin: 0,
              padding: 0,
              display: "block",
              // backgroundImage: `url(${pattern})`,
              // backgroundImage: `linear-gradient(to right, rgba(255,255,255, 0.7) 0 100%), url(${pattern})`,
              // backgroundSize: 'cover',
              backgroundRepeat: "repeat",
              backgroundSize: "contain",
            }}
          />
        </Container>
        <div style={{ borderRadius: 20, overflow: "hidden" }}>
          <Outlet />
        </div>
        <Footer />
      </div>
    </ThemeProvider>
  );
};
