import { Contact } from "../views";

interface ContactFormValues {
  name?: string;
  email?: string;
  message?: string;
}

export interface ContactFormErrors {
  name?: string;
  email?: string;
  message?: string;
}

export interface ValidationErrors {
  hasErrors: boolean;
  errors: ContactFormErrors;
}

export const validateContactForm = (
  values: ContactFormValues
): ValidationErrors => {
  const errors: ContactFormErrors = {};
  const validationErrors = { hasErrors: false, errors };

  if (!values.name) {
    errors.name = "Name is required";
  }
  if (!values.email) {
    errors.email = "Email is required";
  }
  if (!values.message) {
    errors.message = "Message is required";
  }

  if (values.name && values.name.length < 3) {
    errors.name = "Name must be more than 3 characters";
  }

  if (values.email && values.email.length < 3) {
    errors.email = "Email must be more than 3 characters";
  }

  if (values.message && values.message.length < 10) {
    errors.message = "Message must be more than 10 characters";
  }

  if (errors.name || errors.email || errors.message) {
    validationErrors.hasErrors = true;
  }
  return validationErrors;
};
