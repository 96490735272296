import React, { useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Container,
  Box,
  Typography,
  Grid,
  Select,
  InputLabel,
  Button,
  Backdrop,
  CircularProgress,
  Alert,
  AlertTitle,
  Collapse,
  FormControl,
  TextField,
  FormGroup,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddBoxIcon from "@mui/icons-material/AddBox";
import header from "../../../assets/images/banner/b001.jpg";
import SaleSellerContainer from "../../../components/saleSellerContainer/SaleSellerContainer";
import {
  useCurrentUserQuery,
  useSalesQuery,
  useCreateSaleMutation,
  useUpdateSaleMutation,
  useDeleteSaleMutation,
  useProductsQuery,
  Sale,
} from "../../../__generated__/graphql";
import { useNavigate } from "react-router-dom";

type Props = {};

export const DashBoard = ({}: Props) => {
  const { data, loading: userLoading } = useCurrentUserQuery();
  const [selectedOrganisation, setSelectedOrganisation] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [saleLoading, setSaleLoading] = useState<boolean>(false);
  const [sellerName, setSellerName] = useState<string>("");
  const [soldQuantity, setSoldQuantity] = useState<number>(0);
  const [saleId, setSaleId] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [errorTitle, setErrorTitle] = useState<string>("Error");

  const navigate = useNavigate();

  const currentUser = data?.user;
  const organisations = currentUser?.userRoles.map((userRole) => {
    return userRole.organisation;
  });
  const currentOrganisation =
    organisations?.find((org) => org._id === selectedOrganisation) ||
    organisations?.[0];

  const {
    data: salesData,
    loading: salesLoading,
    error: salesError,
    refetch: salesRefetch,
  } = useSalesQuery({
    variables: {
      filter: {
        organisation: selectedOrganisation,
        // ordered: false,
      },
    },
  });

  const {
    data: productsData,
    loading: productsLoading,
    error: productsError,
    refetch: productsRefetch,
  } = useProductsQuery();

  const [createSale] = useCreateSaleMutation();
  const [updateSale] = useUpdateSaleMutation();
  const [deleteSale] = useDeleteSaleMutation();

  const products = productsData?.products;

  useEffect(() => {
    if (
      typeof organisations !== "undefined" &&
      organisations.length > 0 &&
      !selectedOrganisation
    ) {
      setSelectedOrganisation(organisations[0]._id);
    }
  }, [organisations, selectedOrganisation]);

  const onAddSale = async () => {
    setSaleLoading(true);
    const { errors } = saleId
      ? await updateSale({
          variables: {
            id: saleId,
            sale: {
              sellerName,
              item: products?.[0]?._id || "",
              quantity: soldQuantity,
              organisation: selectedOrganisation,
            },
          },
        })
      : await createSale({
          variables: {
            sale: {
              sellerName,
              item: products?.[0]?._id || "",
              quantity: soldQuantity,
              organisation: selectedOrganisation,
            },
          },
        });
    if (errors) {
      setErrorTitle("Kunde inte lägga till försäljning");
      setError(errors[0].message);
      return;
    }
    setSoldQuantity(0);
    setSellerName("");
    await salesRefetch();
    setOpen(false);
    setTimeout(() => {
      setSaleLoading(false);
    }, 500);
  };

  const onEditClick = (item: Sale) => {
    setSaleId(item._id);
    setSellerName(item.sellerName);
    setSoldQuantity(item.quantity);
    setOpen(true);
  };

  const onDeleteClick = async (item: Sale) => {
    const confirmed = window.confirm(
      "Är du säker på att du vill ta bort försäljning?"
    );
    if (!confirmed) return;
    setSaleLoading(true);
    setOpen(true);
    const { errors } = await deleteSale({ variables: { id: item._id } });
    if (errors) {
      setErrorTitle("Kunde inte ta bort försäljning");
      setError(errors[0].message);
      return;
    }
    await salesRefetch();
    setOpen((prev) => !prev);
    setTimeout(() => {
      setSaleLoading(false);
    }, 500);
  };

  const renderOrganisations = () => {
    if (typeof organisations === "undefined") {
      return null;
    }
    if (organisations.length > 1) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingTop: 8,
            paddingRight: 16,
          }}
        >
          <InputLabel id="org-label" style={{ paddingRight: 8 }}>
            Byt organisation
          </InputLabel>
          <Select
            labelId="org-label"
            label="Byt organisation"
            native
            value={selectedOrganisation}
            onChange={(e) => setSelectedOrganisation(e.target.value)}
          >
            {organisations.map((organisation) => {
              return (
                <option
                  value={organisation._id}
                  key={`select-org-dashboard-${organisation._id}`}
                >
                  {organisation.name}
                </option>
              );
            })}
          </Select>
        </div>
      );
    }
    return null;
  };

  const addSaleForm = (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: 10,
        padding: 16,
        width: 400,
        height: 400,
        zIndex: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "space-between",
          marginBottom: 16,
        }}
      >
        <Typography color="InfoText" variant="h5" component="div" gutterBottom>
          Registrera försäljning
        </Typography>
        <IconButton aria-label="close-modal" onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </div>
      <FormGroup>
        <FormControl sx={{ m: 1 }}>
          <TextField
            id="sellerName-input"
            aria-describedby="sellerName-text"
            label="Säljarens namn"
            onChange={(e) => setSellerName(e.target.value)}
            value={sellerName}
            // disabled={loading}
            // error={localValidation.errors.name !== ""}
            required
          />
        </FormControl>
        <FormControl sx={{ m: 1 }}>
          <TextField
            id="quantity-input"
            aria-describedby="quantity-text"
            label={`Antal ${products?.[0]?.name}`}
            onChange={(e) => setSoldQuantity(parseInt(e.target.value))}
            value={soldQuantity}
            // disabled={loading}
            // error={localValidation.errors.name !== ""}
            type="number"
            required
          />
        </FormControl>
        <Button
          variant="contained"
          sx={{ m: 1 }}
          onClick={onAddSale}
          // disabled={loading}
        >
          Lägg till
        </Button>
      </FormGroup>
    </div>
  );

  const renderAddSale = (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      {saleLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Typography
            variant="h5"
            component="div"
            gutterBottom
            style={{ marginTop: 24 }}
          >
            Lägger till försäljning...
          </Typography>
        </div>
      ) : (
        addSaleForm
      )}
    </Backdrop>
  );

  const renderError = () => {
    return (
      <Box sx={{ width: "100%" }}>
        <Collapse in={error !== ""}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setError("");
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            <AlertTitle>{errorTitle}</AlertTitle>
            {error}
          </Alert>
        </Collapse>
      </Box>
    );
  };

  return (
    <>
      <CssBaseline />
      {renderAddSale}
      <Container
        sx={{
          backgroundColor: "white",
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          overflow: "hidden",
        }}
        disableGutters
      >
        <Box
          sx={{
            backgroundImage: `url(${header})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            aspectRatio: "300/200",
            paddingTop: "72px",
            textAlign: "center",
            padding: 0,
          }}
        />
        {renderError()}
        {renderOrganisations()}
        <Box
          sx={{
            width: "100%",
            minHeight: "calc(100vh - 68.5px)",
            flexGrow: 1,
            paddingY: { xs: 2, sm: 2, md: 8 },

            paddingX: { xs: 2, sm: 2, md: 12 },
          }}
        >
          <Typography variant="h2" gutterBottom>
            {currentOrganisation?.name}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 16,
            }}
          >
            <Typography variant="h4" gutterBottom sx={{ marginRight: 8 }}>
              Försäljningar
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ marginBottom: 2 }}
              startIcon={<AddBoxIcon />}
              onClick={() => setOpen(true)}
            >
              Lägg till
            </Button>
          </div>
          <Typography variant="body2" gutterBottom sx={{ marginRight: 8 }}>
            Här lägger ni till försäljningar som ni gjort. Ni kan även redigera
            och ta bort.
          </Typography>
          {salesData &&
            !!salesData.sales?.length &&
            salesData.sales?.map((sale) => {
              if (!sale) return null;
              return (
                <SaleSellerContainer
                  sale={sale}
                  key={`sale-${sale._id}`}
                  onEditClick={onEditClick}
                  onDeleteClick={onDeleteClick}
                />
              );
            })}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: 8,
              fontSize: 24,
              fontWeight: 700,
            }}
          >
            Total:{" "}
            {salesData?.sales
              ? salesData.sales.reduce((acc, sale) => {
                  if (!sale) return acc;
                  return acc + sale.item.retailPrice * sale.quantity;
                }, 0)
              : 0}
            kr
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              paddingRight: 8,
              fontSize: 24,
              fontWeight: 700,
            }}
          >
            <div>
              Ni behåller:{" "}
              {salesData?.sales
                ? salesData.sales.reduce((acc, sale) => {
                    if (!sale) return acc;
                    return acc + sale.item.organisationRevenu * sale.quantity;
                  }, 0)
                : 0}
              kr
            </div>
            {!!salesData?.sales?.length && (
              <Button
                variant="contained"
                color="primary"
                sx={{ marginBottom: 2 }}
                startIcon={<AddBoxIcon />}
                onClick={() => {
                  navigate(`/checkout`, {
                    state: { sales: salesData?.sales },
                  });
                }}
              >
                Skapa beställning
              </Button>
            )}
          </div>

          {/* <Button
            variant="contained"
            color="primary"
            sx={{ marginBottom: 2 }}
            startIcon={<AddBoxIcon />}
            onClick={() => setOpen(true)}
          >
            Lägg till
          </Button> */}
          {/* <Typography variant="h4" gutterBottom sx={{ marginRight: 8 }}>
            Beställningar
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ marginBottom: 2 }}
            startIcon={<AddBoxIcon />}
          >
            Skapa ny beställning
          </Button> */}
        </Box>
      </Container>
    </>
  );
};
