import { CreateOrganisationMutationVariables } from "../../__generated__/graphql";

export interface ValidateOrganisationCreateFormReturn {
  errors: {
    name: string;
    adress: string;
    postalCode: string;
    city: string;
    user: {
      fullName: string;
      email: string;
    };
  };
  hasErrors: boolean;
}

export const validateOrganisationCreateForm = (
  data: CreateOrganisationMutationVariables
): ValidateOrganisationCreateFormReturn => {
  const { name, adress, postalCode, city, user } = data;
  let hasErrors = false;
  const errors = {
    name: "",
    adress: "",
    postalCode: "",
    city: "",
    user: {
      fullName: "",
      email: "",
    },
  };
  if (!name) {
    errors.name = "Name is required";
    hasErrors = true;
  }
  if (!adress) {
    errors.adress = "Adress is required";
    hasErrors = true;
  }
  if (!postalCode) {
    errors.postalCode = "Postal code is required";
    hasErrors = true;
  }
  if (!city) {
    errors.city = "City is required";
    hasErrors = true;
  }
  if (!user.fullName) {
    errors.user.fullName = "Full name is required";
    hasErrors = true;
  }
  if (!user.email) {
    errors.user.email = "Email is required";
    hasErrors = true;
  }

  return { errors, hasErrors };
};
