import { ThemeOptions } from "@mui/material/styles";

export const flavouradoTheme = (prefersDarkMode: boolean): ThemeOptions => ({
  palette: {
    mode: prefersDarkMode ? "dark" : "light",
    primary: {
      main: "#b58c3f",
    },
    secondary: {
      main: "#f5ce00",
    },
    info: {
      main: "#b58c3f",
    },
    background: {
      paper: "#efebe9",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#efebe9",
          backgroundImage: "url(/images/background.gif)",
          margin: 0,
          padding: 0,
        },
      },
    },
  },
});
