import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useMutation, gql } from "@apollo/client";
import {
  validateContactForm,
  ContactFormErrors,
  ValidationErrors,
} from "../validation";
import header from "../assets/images/orange.jpg";

export const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [localValidationErrors, setLocalValidationErrors] =
    useState<ContactFormErrors>();

  const SEND_CONTACT_US = gql`
    mutation contactUs($name: String!, $email: String!, $message: String!) {
      contactUs(name: $name, email: $email, message: $message)
    }
  `;

  const [sendContact, { data, loading, error }] = useMutation(SEND_CONTACT_US);

  const onSendPressed = () => {
    const mailData = {
      name,
      email,
      message,
    };
    setLocalValidationErrors((err) => undefined);
    const localValidationErrors = validateContactForm(mailData);
    if (localValidationErrors.hasErrors) {
      setLocalValidationErrors((err) => localValidationErrors.errors);
      return;
    } else {
      sendContact({
        variables: {
          name,
          email,
          message,
        },
      })
        .then((res) => {
          if (res.data?.contactUs) {
            setName("");
            setEmail("");
            setMessage("");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <CssBaseline />
      <Container
        fixed
        sx={{
          backgroundColor: "white",
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          overflow: "hidden",
        }}
        disableGutters
      >
        <Box
          sx={{
            backgroundImage: `url(${header})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            aspectRatio: "300/200",
            paddingTop: "72px",
            textAlign: "center",
          }}
        />
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            paddingTop: { xs: 2, sm: 2, md: 8 },
            paddingX: { xs: 2, sm: 2, md: 12 },
          }}
        >
          <Typography variant="h2" gutterBottom>
            Kontakta oss
          </Typography>
          <div>
            <Box
              sx={{
                maxWidth: "100%",
                marginBottom: "24px",
              }}
            >
              <TextField
                fullWidth
                id="outlined-basic"
                label="Namn"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={!!localValidationErrors?.name}
                helperText={localValidationErrors?.name}
                required
                disabled={loading}
              />
            </Box>
            <Box
              sx={{
                maxWidth: "100%",
                marginBottom: "24px",
              }}
            >
              <TextField
                fullWidth
                id="outlined-basic"
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!!localValidationErrors?.email}
                helperText={localValidationErrors?.email}
                required
                disabled={loading}
              />
            </Box>
            <Box
              sx={{
                maxWidth: "100%",
                marginBottom: "24px",
              }}
            >
              <TextField
                fullWidth
                multiline
                id="outlined-basic"
                label="Meddelande"
                variant="outlined"
                rows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                error={!!localValidationErrors?.message}
                helperText={localValidationErrors?.message}
                required
                disabled={loading}
              />
            </Box>
            <Box sx={{ m: 1, position: "relative" }}>
              <Button
                variant="contained"
                onClick={onSendPressed}
                disabled={loading}
              >
                Skicka
                {loading && (
                  <CircularProgress
                    size={18}
                    sx={{
                      color: green[600],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-9px",
                      marginLeft: "-9px",
                    }}
                  />
                )}
              </Button>
            </Box>
          </div>
        </Box>
      </Container>
    </>
  );
};
