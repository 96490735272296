import React from "react";
import { Button, ButtonProps } from "@mui/material";
import styled from "@emotion/styled";

type Props = {};

const FlButton = styled(Button)<ButtonProps>(({ theme }) => ({
  width: "300px",
  height: "80px",
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingLeft: "40px",
  paddingRight: "40px",
  fontSize: "24px",
  backgroundColor: "#aa9876",
  "&:hover": {
    backgroundColor: "#d2cab0",
    color: "#FFFEEE",
  },
  color: "#FFFEEE",
  // borderRadius: "40px",
}));

export default FlButton;
