import React from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { IconButton } from "@mui/material";
import { Sale } from "../../__generated__/graphql";

interface Props {
  sale: Sale;
  onEditClick: (sale: Sale) => void;
  onDeleteClick: (sale: Sale) => void;
}

export default function SaleSellerContainer({
  sale,
  onEditClick,
  onDeleteClick,
}: Props) {
  if (!sale) return null;
  const { _id, sellerName, item, ordered, payedDate, quantity } = sale;
  return (
    <div
      style={{
        position: "relative",
        boxShadow: "5px 0px 10px 0px rgba(0,0,0,0.1)",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top: -15,
          left: 15,
          //   backgroundColor: "rgba(0,0,0,0)",
          backgroundColor: "white",
          color: "red",
          zIndex: 1,
          width: 50,
          height: 30,
          borderRadius: 10,
          border: "0.3px solid rgba(0,0,0,0.2)",
          fontWeight: "bold",
        }}
      >
        <div>Ny</div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top: 5,
          right: 5,
          //   backgroundColor: "rgba(0,0,0,0)",
          backgroundColor: "white",
          color: "red",
          zIndex: 1,
          //   width: 50,
          //   height: 30,
          //   borderRadius: 10,
          //   border: "0.3px solid rgba(0,0,0,0.2)",
          fontWeight: "bold",
        }}
      >
        <IconButton aria-label="edit" onClick={() => onEditClick(sale)}>
          <EditOutlinedIcon />
        </IconButton>
        <IconButton aria-label="delete" onClick={() => onDeleteClick(sale)}>
          <DeleteOutlinedIcon />
        </IconButton>
      </div>
      <div
        style={{
          paddingTop: 30,
          paddingLeft: 20,
          paddingRight: 20,
          paddingBottom: 20,
          borderRadius: 4,
          backgroundColor: "white",
          marginBottom: 20,
          marginTop: 20,
          border: "0.3px solid rgba(0,0,0,0.2)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ fontWeight: "bold" }}>{sellerName}</div>
          {/* <div>
            <IconButton aria-label="edit">
              <EditOutlinedIcon />
            </IconButton>
            <IconButton aria-label="delete">
              <DeleteOutlinedIcon />
            </IconButton>
          </div> */}
        </div>
        <div>
          {
            // sales.map((sale) => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                {quantity} x {item.name} - {item.retailPrice}kr{" "}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexGrow: 1,
                  borderBottomColor: "#000000",
                  borderBottomStyle: "dotted",
                  borderWidth: 1,
                  marginBottom: 6,
                  marginLeft: 6,
                  marginRight: 6,
                }}
              />
              <div>{`${quantity * item.retailPrice}kr`}</div>
            </div>
            // ))
          }
          {/* <div
            style={{
              textAlign: "right",
              fontWeight: "bold",
              paddingTop: 6,
            }}
          >
            Total: {sales.reduce((acc, sale) => acc + sale.total, 0)}kr
          </div> */}
        </div>
      </div>
    </div>
  );
}
