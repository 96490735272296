import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { CardActionArea } from "@mui/material";
import { Product } from "../../__generated__/graphql";

type Props = {
  product: Product;
  index: number;
};

export const ProductCard = ({ product, index }: Props) => {
  const xsSize = 12;
  const smSize = index ? 6 : 12;
  const lgSize = index ? 4 : 12;

  return (
    <Grid xs={xsSize} sm={smSize} lg={lgSize} style={{ display: "flex" }}>
      <Card sx={{ width: "100%" }} raised>
        <CardActionArea>
          <CardMedia
            component="img"
            height="250"
            image={product.image}
            alt={product.name}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {product.name}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                whiteSpace: "pre-wrap",
              }}
            >
              {product.description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};
