import React from "react";
import { Routes, Route } from "react-router-dom";
import {
  Root,
  Home,
  About,
  Contact,
  Products,
  ErrorPage,
  Login,
  Checkout,
  DashBoard,
} from "../views";
import { useReactiveVar } from "@apollo/client";
import { isLoggedInVar } from "../apollo/client";

// const publicOnlyRoutes: RouteObject[] = [
//   { path: "/login", element: <Login /> },
// ];

// const orgAdminRoutes: RouteObject[] = [
//   { path: "/dashboard", element: <DashBoard /> },
// ];

// export const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <Root />,
//     errorElement: <ErrorPage />,
//     children: [
//       { path: "/", element: <Home /> },
//       { path: "/produkter", element: <Products /> },
//       { path: "/omoss", element: <About /> },
//       { path: "/kontakt", element: <Contact /> },
//       { path: "/checkout", element: <Checkout /> },
//       ...publicOnlyRoutes,
//       ...orgAdminRoutes,
//     ],
//   },
// ]);

export const AuthRoutes = () => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  if (isLoggedIn) {
    return <Route path="/dashboard" element={<DashBoard />} />;
  } else {
    return <Route path="/login" element={<Login />} />;
  }
};

export const Router = () => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  return (
    <Routes>
      <Route path="/" element={<Root />} errorElement={<ErrorPage />}>
        <Route path="/" element={<Home />} />
        <Route path="/produkter" element={<Products />} />
        <Route path="/omoss" element={<About />} />
        <Route path="/kontakt" element={<Contact />} />
        <Route path="/checkout" element={<Checkout />} />
        {isLoggedIn ? (
          <Route path="/dashboard" element={<DashBoard />} />
        ) : (
          <Route path="/login" element={<Login />} />
        )}
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Routes>
  );
};
