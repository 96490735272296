import React from "react";

export const ErrorPage = () => {
  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>We can't find what you are looking for (AKA 404).</p>
    </div>
  );
};
