import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import {
  useGetEmailCodeMutation,
  useVerifyCodeMutation,
} from "../../__generated__/graphql";
import header from "../../assets/images/orange.jpg";
import { replaceLinkChainOnClient, isLoggedInVar } from "../../apollo/client";
import { useNavigate } from "react-router-dom";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const [
    verifyCode,
    { loading: verifyCodeLoading, error: verifyCodeError, client },
  ] = useVerifyCodeMutation();

  const [
    getEmailCode,
    { loading: getCodeLoading, error: getCodeError, data: getCodeData },
  ] = useGetEmailCodeMutation();

  const onSendPressed = () => {
    if (getCodeData?.getEmailCode) {
      verifyCode({
        variables: {
          email,
          code: password,
        },
      })
        .then((res) => {
          if (res.data?.verifyCode?.token) {
            const token = res.data?.verifyCode?.token;
            localStorage.setItem("token", token);
            replaceLinkChainOnClient(client, token);
            setEmail("");
            setPassword("");
            isLoggedInVar(true);
            navigate("/dashboard");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      getEmailCode({
        variables: {
          email,
        },
      });
    }
  };

  return (
    <>
      <CssBaseline />
      <Container
        fixed
        sx={{
          backgroundColor: "white",
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          overflow: "hidden",
        }}
        disableGutters
      >
        <Box
          sx={{
            backgroundImage: `url(${header})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            aspectRatio: "300/200",
            paddingTop: "72px",
            textAlign: "center",
          }}
        />
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            paddingTop: 8,
            paddingX: { xs: 0, sm: 2, md: 3, lg: 4 },
          }}
        >
          <Typography variant="h2" gutterBottom>
            Logga in
          </Typography>
          <div>
            <Box
              sx={{
                maxWidth: "100%",
                marginBottom: "24px",
              }}
            >
              <TextField
                type={"text"}
                fullWidth
                id="outlined-basic"
                label="Epost"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                disabled={verifyCodeLoading || getCodeData?.getEmailCode}
                inputProps={{
                  autoComplete: "off",
                }}
                autoFocus={!getCodeData?.getEmailCode}
              />
            </Box>
            {getCodeData?.getEmailCode ? (
              <>
                <Typography variant="body1" sx={{ mt: 1, marginBottom: 2 }}>
                  Vi skickade ett mail till {email} med en kod. Skriv in koden.
                </Typography>

                <Box
                  sx={{
                    maxWidth: "100%",
                    marginBottom: "24px",
                  }}
                >
                  <TextField
                    type="password"
                    fullWidth
                    id="outlined-basic"
                    label="Kod"
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    disabled={verifyCodeLoading}
                    autoFocus
                  />
                </Box>
              </>
            ) : null}

            <Box
              sx={{
                m: 1,
                position: "relative",
                flexDirection: "row",
                display: "flex",
              }}
            >
              <Button
                variant="contained"
                onClick={onSendPressed}
                disabled={verifyCodeLoading || getCodeLoading}
              >
                Skicka
                {(verifyCodeLoading || getCodeLoading) && (
                  <CircularProgress
                    size={18}
                    sx={{
                      color: green[600],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-9px",
                      marginLeft: "-9px",
                    }}
                  />
                )}
              </Button>
              {verifyCodeError || getCodeError ? (
                <Typography
                  variant="body2"
                  color="red"
                  sx={{ mt: 1, marginLeft: 2 }}
                >
                  {verifyCodeError?.message}
                  {getCodeError?.message}
                </Typography>
              ) : null}
            </Box>
          </div>
        </Box>
      </Container>
    </>
  );
};
