import React from "react";
import { Typography } from "@mui/material";

type Props = {
  title: string;
  style?: React.CSSProperties;
};

export default function ParagraphTitle({ title, style }: Props) {
  return (
    <div
      style={{
        textAlign: "center",
        width: "100%",
        // paddingTop: "12px",
        paddingBottom: "12px",
        // color: "#FFFEEE",
        // fontWeight: "bold",
        backgroundImage:
          "linear-gradient(to left top, #fffeee, #d2cab0, #aa9876, #856741, #603813)",
        ...style,
      }}
    >
      <Typography
        variant="h4"
        color={"#FFFEEE"}
        paddingTop={6}
        paddingLeft={6}
        paddingRight={6}
      >
        {title}
      </Typography>
    </div>
  );
}
