import React, { useState, useEffect } from "react";
import { Backdrop, Button, Container, Typography } from "@mui/material";
import { FormControl, TextField, FormGroup, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  useCreateOrganisationMutation,
  CreateOrganisationMutation,
} from "../../__generated__/graphql";
import {
  validateOrganisationCreateForm,
  ValidateOrganisationCreateFormReturn,
} from "./validation";

type Props = {
  visible?: boolean;
  close?: () => void;
};

const StartOverlay = ({ visible = true, close = () => null }: Props) => {
  const [name, setName] = useState("");
  const [adress, setAdress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [localValidation, setLocalValidation] =
    useState<ValidateOrganisationCreateFormReturn>({
      hasErrors: false,
      errors: {
        name: "",
        adress: "",
        postalCode: "",
        city: "",
        user: {
          fullName: "",
          email: "",
        },
      },
    });

  const [createOrganisation, { data, loading, error, reset }] =
    useCreateOrganisationMutation();

  const handleSubmit = () => {
    const input = {
      name,
      adress,
      postalCode,
      city,
      user: {
        fullName: userName,
        email,
        password: "",
        password2: "",
      },
    };
    const validation = validateOrganisationCreateForm(input);
    setLocalValidation(validation);
    if (validation.hasErrors) {
      return;
    }
    createOrganisation({ variables: { ...input } });
    // close();
  };

  const renderSuccess = ({
    createOrganisation,
  }: CreateOrganisationMutation) => {
    return (
      <div
        style={{
          backgroundColor: "#FFFEEE",
          borderRadius: 4,
          padding: 24,
          minHeight: "100%",
        }}
      >
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5" component="div" gutterBottom>
              Färdig!
            </Typography>
            <IconButton aria-label="close-modal" onClick={() => close()}>
              <CloseIcon />
            </IconButton>
          </div>

          <Typography variant="body1" component="div" gutterBottom>
            Vi har skickat ett mail till {email} med all material och
            information ni behöver för att komma igång.
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            Vi har registrerat följande uppgifter:
          </Typography>
          <Typography variant="h5" component="div" gutterBottom>
            Skola eller förening
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            Namn {createOrganisation?.name} <br />
            Address: {createOrganisation?.adress} <br />
            Postkod: {createOrganisation?.postalCode} <br />
            Ort: {createOrganisation?.city} <br />
            Ditt namn: {userName} <br />
            Din epost: {email}
          </Typography>
          <Typography variant="h5" component="div" gutterBottom>
            Ansvarig
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            Namn: {userName} <br />
            Din epost: {email}
          </Typography>
          <Button
            variant="contained"
            sx={{ marginTop: 1 }}
            onClick={() => {
              close();
              reset();
            }}
            disabled={loading}
          >
            Stäng
          </Button>
        </>
      </div>
    );
  };

  const renderForm = (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5" component="div" gutterBottom>
          Vad kul att ni vill komma igång!
        </Typography>
        <IconButton aria-label="close-modal" onClick={() => close()}>
          <CloseIcon />
        </IconButton>
      </div>
      <Typography variant="body1" component="div" gutterBottom>
        Fyll i formuläret nedan så skickar vi en startkit. Ni kan börja sälja
        direkt!
      </Typography>
      <FormGroup>
        <FormControl sx={{ m: 1 }}>
          <TextField
            id="orgName-input"
            aria-describedby="orgName-text"
            label="Organisationens namn"
            onChange={(e) => setName(e.target.value)}
            disabled={loading}
            error={localValidation.errors.name !== ""}
            required
          />
        </FormControl>
        <FormControl sx={{ m: 1 }}>
          <TextField
            id="adress-input"
            aria-describedby="orgAdress-text"
            label="Organisationens address"
            onChange={(e) => setAdress(e.target.value)}
            disabled={loading}
            error={localValidation.errors.adress !== ""}
            required
          />
        </FormControl>
        {/* <FormGroup row> */}
        <FormControl sx={{ m: 1 }}>
          <TextField
            id="postCode-input"
            aria-describedby="postCode-text"
            label="Postkod"
            onChange={(e) => setPostalCode(e.target.value)}
            disabled={loading}
            error={localValidation.errors.postalCode !== ""}
            required
          />
        </FormControl>
        <FormControl sx={{ m: 1 }}>
          <TextField
            id="city-input"
            aria-describedby="emacityxt"
            label="Ort"
            onChange={(e) => setCity(e.target.value)}
            disabled={loading}
            error={localValidation.errors.city !== ""}
            required
          />
        </FormControl>
        {/* </FormGroup> */}
        <FormControl sx={{ m: 1 }}>
          <TextField
            id="userName-input"
            aria-describedby="userName-text"
            label="Ditt namn"
            onChange={(e) => setUserName(e.target.value)}
            disabled={loading}
            error={localValidation.errors.user.fullName !== ""}
            required
          />
        </FormControl>
        <FormControl sx={{ m: 1 }}>
          <TextField
            id="email-input"
            aria-describedby="email-text"
            label="Epost"
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
            error={localValidation.errors.user.email !== ""}
            required
          />
        </FormControl>
        <Button
          variant="contained"
          sx={{ m: 1 }}
          onClick={handleSubmit}
          disabled={loading}
        >
          Skicka
        </Button>
      </FormGroup>
    </>
  );

  const renderContent = () => {
    // if (loading) {
    //   return <div>Loading...</div>;
    // }
    if (error) {
      return <div>Error: {error.message}</div>;
    }
    if (data) {
      return renderSuccess(data);
    }
    return renderForm;
  };

  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={visible}
      onClick={() => close()}
    >
      <Container
        style={{
          padding: "20px",
          height: "100%",
        }}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          style={{
            backgroundColor: "#FFFEEE",
            borderRadius: 4,
            padding: 24,
            minHeight: "100%",
          }}
        >
          {renderContent()}
        </div>
      </Container>
    </Backdrop>
  );
};

export default StartOverlay;
