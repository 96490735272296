import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions, Appearance } from "@stripe/stripe-js";
import { useCreatePaymentIntendMutation } from "../../__generated__/graphql";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import { Sale } from "../../__generated__/graphql";

import CheckoutForm from "../../components/checkoutForm/CheckoutForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE!);

type Props = {};

export const Checkout = (props: Props) => {
  const [clientSecret, setClientSecret] = useState("");
  const [createPaymentIntent, { data, error, loading }] =
    useCreatePaymentIntendMutation();

  const location = useLocation();
  const { sales }: { sales: Sale[] } = location.state;
  const cartItems = sales.map((sale) => ({ productId: sale._id }));

  useEffect(() => {
    if (!loading && !data && cartItems.length > 0) {
      createPaymentIntent({
        variables: {
          cartItems,
          delivery: {
            address1: "",
            city: "",
            country: "",
            firstName: "",
            lastName: "",
            phone: "",
            postalCode: "",
            email: "",
          },
        },
      })
        .then((res) => {
          console.log(res);
          return res;
        })
        .then((res) => {
          setClientSecret(res.data?.createPaymentIntend.clientSecret || "");
        });
    }
  }, []);

  const appearance: Appearance = {
    theme: "stripe",
  };
  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
  };

  const content = clientSecret ? (
    <Elements options={options} stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  ) : (
    <div>Checkout</div>
  );

  return (
    <>
      <CssBaseline />
      <Container fixed style={{ backgroundColor: "white" }}>
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            padding: "8px",
          }}
        >
          <Typography variant="h2" gutterBottom>
            Betala
          </Typography>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
            }}
          >
            <div>whatever</div>
            {content}
          </div>
        </Box>
      </Container>
    </>
  );
};

export default Checkout;
