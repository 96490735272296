import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type AuthData = {
  __typename?: 'AuthData';
  token: Scalars['String']['output'];
};

export type CartItem = {
  __typename?: 'CartItem';
  productId: Scalars['ID']['output'];
};

export type CartItemInput = {
  productId: Scalars['ID']['input'];
};

export type DeliveryDetails = {
  __typename?: 'DeliveryDetails';
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
};

export type DeliveryDetailsInput = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  contactUs: Scalars['Boolean']['output'];
  createOrder: Order;
  createOrganisation?: Maybe<Organisation>;
  createPaymentIntend: PaymentItend;
  createProduct?: Maybe<Product>;
  createRole?: Maybe<Role>;
  createSale?: Maybe<Sale>;
  createUser: AuthData;
  createUserRole: UserRole;
  deleteProduct?: Maybe<Product>;
  deleteSale?: Maybe<Sale>;
  deleteUserRole: UserRole;
  getEmailCode: Scalars['Boolean']['output'];
  login: AuthData;
  updateProduct?: Maybe<Product>;
  updateSale?: Maybe<Sale>;
  updateUserRole: UserRole;
  verifyCode: AuthData;
};


export type MutationContactUsArgs = {
  email: Scalars['String']['input'];
  message: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationCreateOrderArgs = {
  items: Array<OrderItemInput>;
  organisation: Scalars['ID']['input'];
  shippingAddress: ShippingAddressInput;
};


export type MutationCreateOrganisationArgs = {
  adress: Scalars['String']['input'];
  city: Scalars['String']['input'];
  name: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  user: UserInput;
};


export type MutationCreatePaymentIntendArgs = {
  cartItems: Array<InputMaybe<CartItemInput>>;
  delivery: DeliveryDetailsInput;
};


export type MutationCreateProductArgs = {
  productInput: ProductInput;
};


export type MutationCreateRoleArgs = {
  roleInput?: InputMaybe<RoleInput>;
};


export type MutationCreateSaleArgs = {
  sale?: InputMaybe<SaleInput>;
};


export type MutationCreateUserArgs = {
  userInput?: InputMaybe<UserInput>;
};


export type MutationCreateUserRoleArgs = {
  input: UserRoleInput;
  userRoleInput?: InputMaybe<UserRoleInput>;
};


export type MutationDeleteProductArgs = {
  _id: Scalars['ID']['input'];
};


export type MutationDeleteSaleArgs = {
  _id: Scalars['ID']['input'];
};


export type MutationDeleteUserRoleArgs = {
  id: Scalars['ID']['input'];
};


export type MutationGetEmailCodeArgs = {
  email: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationUpdateProductArgs = {
  _id: Scalars['ID']['input'];
  productInput: ProductInput;
};


export type MutationUpdateSaleArgs = {
  _id: Scalars['ID']['input'];
  sale?: InputMaybe<SaleInput>;
};


export type MutationUpdateUserRoleArgs = {
  id: Scalars['ID']['input'];
  input: UserRoleInput;
};


export type MutationVerifyCodeArgs = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
};

export type Order = {
  __typename?: 'Order';
  _id: Scalars['ID']['output'];
  createdAt: Scalars['String']['output'];
  createdBy: User;
  items: Array<OrderItem>;
  organisation: Organisation;
  payedDate?: Maybe<Scalars['String']['output']>;
  shippingAddress: ShippingAddress;
  status: OrderStatus;
  total: Scalars['Float']['output'];
  updatedAt: Scalars['String']['output'];
};

export type OrderItem = {
  __typename?: 'OrderItem';
  product: Product;
  quantity: Scalars['Int']['output'];
};

export type OrderItemInput = {
  product: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
};

export enum OrderStatus {
  Cancelled = 'CANCELLED',
  Delivered = 'DELIVERED',
  New = 'NEW',
  Processing = 'PROCESSING',
  Shipped = 'SHIPPED'
}

export type Organisation = {
  __typename?: 'Organisation';
  _id: Scalars['ID']['output'];
  adress: Scalars['String']['output'];
  city: Scalars['String']['output'];
  name: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  sales: Array<Sale>;
  userRoles: Array<UserRole>;
};

export type PaymentItend = {
  __typename?: 'PaymentItend';
  amountExVat: Scalars['Float']['output'];
  amountIncVat: Scalars['Float']['output'];
  cartItems: Array<CartItem>;
  clientSecret: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  delivery: DeliveryDetails;
  shipingCost: Scalars['Float']['output'];
  weight: Scalars['Float']['output'];
};

export type Product = {
  __typename?: 'Product';
  _id: Scalars['ID']['output'];
  createdAt: Scalars['String']['output'];
  description: Scalars['String']['output'];
  disabled: Scalars['Boolean']['output'];
  image: Scalars['String']['output'];
  margin: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  organisationRevenu: Scalars['Float']['output'];
  ourPriceExVat: Scalars['Float']['output'];
  ourPriceIncVat: Scalars['Float']['output'];
  retailPrice: Scalars['Float']['output'];
  updatedAt: Scalars['String']['output'];
  weight: Scalars['Float']['output'];
};

export type ProductInput = {
  description: Scalars['String']['input'];
  disabled: Scalars['Boolean']['input'];
  image: Scalars['String']['input'];
  name: Scalars['String']['input'];
  ourPriceExVat: Scalars['Float']['input'];
  retailPrice: Scalars['Float']['input'];
  weight: Scalars['Float']['input'];
};

export type Query = {
  __typename?: 'Query';
  order: Order;
  orders: Array<Order>;
  organisation: Organisation;
  organisations: Array<Organisation>;
  product: Product;
  products: Array<Product>;
  role?: Maybe<Role>;
  roles?: Maybe<Array<Maybe<Role>>>;
  sale?: Maybe<Sale>;
  sales?: Maybe<Array<Maybe<Sale>>>;
  user: User;
  userRole: UserRole;
  userRoles: Array<UserRole>;
  users: Array<User>;
};


export type QueryOrderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrdersArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryOrganisationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryProductsArgs = {
  productInput?: InputMaybe<SearchProductInput>;
};


export type QueryRoleArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySaleArgs = {
  _id: Scalars['ID']['input'];
};


export type QuerySalesArgs = {
  filter: SaleQueryInput;
};


export type QueryUserArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUserRoleArgs = {
  id: Scalars['ID']['input'];
};

export type Role = {
  __typename?: 'Role';
  _id: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  userRoles?: Maybe<Array<Maybe<UserRole>>>;
};

export type RoleInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type Sale = {
  __typename?: 'Sale';
  _id: Scalars['ID']['output'];
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  item: Product;
  ordered: Scalars['Boolean']['output'];
  organisation: Scalars['String']['output'];
  payedDate?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  sellerName: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type SaleInput = {
  item: Scalars['String']['input'];
  organisation: Scalars['String']['input'];
  payedDate?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Int']['input'];
  sellerName: Scalars['String']['input'];
};

export type SaleQueryInput = {
  item?: InputMaybe<Scalars['String']['input']>;
  ordered?: InputMaybe<Scalars['Boolean']['input']>;
  organisation?: InputMaybe<Scalars['String']['input']>;
  payedDate?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  sellerName?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

export type SearchProductInput = {
  _id?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ourPriceExVat?: InputMaybe<Scalars['Float']['input']>;
  retailPrice?: InputMaybe<Scalars['Float']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type ShippingAddress = {
  __typename?: 'ShippingAddress';
  address: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  name: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type ShippingAddressInput = {
  address: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  name: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  _id: Scalars['ID']['output'];
  createdAt: Scalars['String']['output'];
  email: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  userRoles: Array<UserRole>;
};

export type UserInput = {
  email: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  password2: Scalars['String']['input'];
};

export type UserRole = {
  __typename?: 'UserRole';
  _id: Scalars['ID']['output'];
  organisation: Organisation;
  role: Role;
  user: User;
};

export type UserRoleInput = {
  organisationId: Scalars['ID']['input'];
  roleId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type CreateOrganisationMutationVariables = Exact<{
  name: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  city: Scalars['String']['input'];
  adress: Scalars['String']['input'];
  user: UserInput;
}>;


export type CreateOrganisationMutation = { __typename?: 'Mutation', createOrganisation?: { __typename?: 'Organisation', postalCode: string, name: string, city: string, adress: string, _id: string } | null };

export type ProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductsQuery = { __typename?: 'Query', products: Array<{ __typename?: 'Product', _id: string, name: string, description: string, image: string, createdAt: string, updatedAt: string }> };

export type CreatePaymentIntendMutationVariables = Exact<{
  cartItems: Array<CartItemInput> | CartItemInput;
  delivery: DeliveryDetailsInput;
}>;


export type CreatePaymentIntendMutation = { __typename?: 'Mutation', createPaymentIntend: { __typename?: 'PaymentItend', clientSecret: string, amountIncVat: number, currency: string, amountExVat: number, shipingCost: number, weight: number, cartItems: Array<{ __typename?: 'CartItem', productId: string }>, delivery: { __typename?: 'DeliveryDetails', firstName: string, lastName: string, email: string, address1: string, address2?: string | null, city: string, postalCode: string, country: string, phone: string } } };

export type GetEmailCodeMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type GetEmailCodeMutation = { __typename?: 'Mutation', getEmailCode: boolean };

export type VerifyCodeMutationVariables = Exact<{
  email: Scalars['String']['input'];
  code: Scalars['String']['input'];
}>;


export type VerifyCodeMutation = { __typename?: 'Mutation', verifyCode: { __typename?: 'AuthData', token: string } };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', user: { __typename?: 'User', _id: string, fullName: string, email: string, createdAt: string, updatedAt?: string | null, userRoles: Array<{ __typename?: 'UserRole', organisation: { __typename?: 'Organisation', postalCode: string, name: string, city: string, adress: string, _id: string }, role: { __typename?: 'Role', name: string, description: string, _id: string } }> } };

export type SalesQueryVariables = Exact<{
  filter: SaleQueryInput;
}>;


export type SalesQuery = { __typename?: 'Query', sales?: Array<{ __typename?: 'Sale', _id: string, organisation: string, createdBy: string, sellerName: string, quantity: number, payedDate?: string | null, createdAt: string, updatedAt?: string | null, ordered: boolean, item: { __typename?: 'Product', _id: string, name: string, description: string, image: string, ourPriceExVat: number, ourPriceIncVat: number, retailPrice: number, organisationRevenu: number, margin: number, createdAt: string, updatedAt: string, disabled: boolean, weight: number } } | null> | null };

export type CreateSaleMutationVariables = Exact<{
  sale?: InputMaybe<SaleInput>;
}>;


export type CreateSaleMutation = { __typename?: 'Mutation', createSale?: { __typename?: 'Sale', _id: string } | null };

export type UpdateSaleMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  sale?: InputMaybe<SaleInput>;
}>;


export type UpdateSaleMutation = { __typename?: 'Mutation', updateSale?: { __typename?: 'Sale', _id: string } | null };

export type DeleteSaleMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteSaleMutation = { __typename?: 'Mutation', deleteSale?: { __typename?: 'Sale', _id: string } | null };


export const CreateOrganisationDocument = gql`
    mutation CreateOrganisation($name: String!, $postalCode: String!, $city: String!, $adress: String!, $user: UserInput!) {
  createOrganisation(
    name: $name
    postalCode: $postalCode
    city: $city
    adress: $adress
    user: $user
  ) {
    postalCode
    name
    city
    adress
    _id
  }
}
    `;
export type CreateOrganisationMutationFn = Apollo.MutationFunction<CreateOrganisationMutation, CreateOrganisationMutationVariables>;

/**
 * __useCreateOrganisationMutation__
 *
 * To run a mutation, you first call `useCreateOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganisationMutation, { data, loading, error }] = useCreateOrganisationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      postalCode: // value for 'postalCode'
 *      city: // value for 'city'
 *      adress: // value for 'adress'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrganisationMutation, CreateOrganisationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrganisationMutation, CreateOrganisationMutationVariables>(CreateOrganisationDocument, options);
      }
export type CreateOrganisationMutationHookResult = ReturnType<typeof useCreateOrganisationMutation>;
export type CreateOrganisationMutationResult = Apollo.MutationResult<CreateOrganisationMutation>;
export type CreateOrganisationMutationOptions = Apollo.BaseMutationOptions<CreateOrganisationMutation, CreateOrganisationMutationVariables>;
export const ProductsDocument = gql`
    query Products {
  products {
    _id
    name
    description
    image
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductsQuery(baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
      }
export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const CreatePaymentIntendDocument = gql`
    mutation CreatePaymentIntend($cartItems: [CartItemInput!]!, $delivery: DeliveryDetailsInput!) {
  createPaymentIntend(cartItems: $cartItems, delivery: $delivery) {
    clientSecret
    amountIncVat
    currency
    amountExVat
    shipingCost
    weight
    cartItems {
      productId
    }
    delivery {
      firstName
      lastName
      email
      address1
      address2
      city
      postalCode
      country
      phone
    }
  }
}
    `;
export type CreatePaymentIntendMutationFn = Apollo.MutationFunction<CreatePaymentIntendMutation, CreatePaymentIntendMutationVariables>;

/**
 * __useCreatePaymentIntendMutation__
 *
 * To run a mutation, you first call `useCreatePaymentIntendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentIntendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentIntendMutation, { data, loading, error }] = useCreatePaymentIntendMutation({
 *   variables: {
 *      cartItems: // value for 'cartItems'
 *      delivery: // value for 'delivery'
 *   },
 * });
 */
export function useCreatePaymentIntendMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentIntendMutation, CreatePaymentIntendMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentIntendMutation, CreatePaymentIntendMutationVariables>(CreatePaymentIntendDocument, options);
      }
export type CreatePaymentIntendMutationHookResult = ReturnType<typeof useCreatePaymentIntendMutation>;
export type CreatePaymentIntendMutationResult = Apollo.MutationResult<CreatePaymentIntendMutation>;
export type CreatePaymentIntendMutationOptions = Apollo.BaseMutationOptions<CreatePaymentIntendMutation, CreatePaymentIntendMutationVariables>;
export const GetEmailCodeDocument = gql`
    mutation GetEmailCode($email: String!) {
  getEmailCode(email: $email)
}
    `;
export type GetEmailCodeMutationFn = Apollo.MutationFunction<GetEmailCodeMutation, GetEmailCodeMutationVariables>;

/**
 * __useGetEmailCodeMutation__
 *
 * To run a mutation, you first call `useGetEmailCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetEmailCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getEmailCodeMutation, { data, loading, error }] = useGetEmailCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetEmailCodeMutation(baseOptions?: Apollo.MutationHookOptions<GetEmailCodeMutation, GetEmailCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetEmailCodeMutation, GetEmailCodeMutationVariables>(GetEmailCodeDocument, options);
      }
export type GetEmailCodeMutationHookResult = ReturnType<typeof useGetEmailCodeMutation>;
export type GetEmailCodeMutationResult = Apollo.MutationResult<GetEmailCodeMutation>;
export type GetEmailCodeMutationOptions = Apollo.BaseMutationOptions<GetEmailCodeMutation, GetEmailCodeMutationVariables>;
export const VerifyCodeDocument = gql`
    mutation VerifyCode($email: String!, $code: String!) {
  verifyCode(email: $email, code: $code) {
    token
  }
}
    `;
export type VerifyCodeMutationFn = Apollo.MutationFunction<VerifyCodeMutation, VerifyCodeMutationVariables>;

/**
 * __useVerifyCodeMutation__
 *
 * To run a mutation, you first call `useVerifyCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyCodeMutation, { data, loading, error }] = useVerifyCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useVerifyCodeMutation(baseOptions?: Apollo.MutationHookOptions<VerifyCodeMutation, VerifyCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyCodeMutation, VerifyCodeMutationVariables>(VerifyCodeDocument, options);
      }
export type VerifyCodeMutationHookResult = ReturnType<typeof useVerifyCodeMutation>;
export type VerifyCodeMutationResult = Apollo.MutationResult<VerifyCodeMutation>;
export type VerifyCodeMutationOptions = Apollo.BaseMutationOptions<VerifyCodeMutation, VerifyCodeMutationVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  user {
    _id
    fullName
    email
    createdAt
    updatedAt
    userRoles {
      organisation {
        postalCode
        name
        city
        adress
        _id
      }
      role {
        name
        description
        _id
      }
    }
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const SalesDocument = gql`
    query Sales($filter: SaleQueryInput!) {
  sales(filter: $filter) {
    _id
    organisation
    createdBy
    sellerName
    item {
      _id
      name
      description
      image
      ourPriceExVat
      ourPriceIncVat
      retailPrice
      organisationRevenu
      margin
      createdAt
      updatedAt
      disabled
      weight
    }
    quantity
    payedDate
    createdAt
    updatedAt
    ordered
  }
}
    `;

/**
 * __useSalesQuery__
 *
 * To run a query within a React component, call `useSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSalesQuery(baseOptions: Apollo.QueryHookOptions<SalesQuery, SalesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesQuery, SalesQueryVariables>(SalesDocument, options);
      }
export function useSalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesQuery, SalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesQuery, SalesQueryVariables>(SalesDocument, options);
        }
export type SalesQueryHookResult = ReturnType<typeof useSalesQuery>;
export type SalesLazyQueryHookResult = ReturnType<typeof useSalesLazyQuery>;
export type SalesQueryResult = Apollo.QueryResult<SalesQuery, SalesQueryVariables>;
export const CreateSaleDocument = gql`
    mutation CreateSale($sale: SaleInput) {
  createSale(sale: $sale) {
    _id
  }
}
    `;
export type CreateSaleMutationFn = Apollo.MutationFunction<CreateSaleMutation, CreateSaleMutationVariables>;

/**
 * __useCreateSaleMutation__
 *
 * To run a mutation, you first call `useCreateSaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSaleMutation, { data, loading, error }] = useCreateSaleMutation({
 *   variables: {
 *      sale: // value for 'sale'
 *   },
 * });
 */
export function useCreateSaleMutation(baseOptions?: Apollo.MutationHookOptions<CreateSaleMutation, CreateSaleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSaleMutation, CreateSaleMutationVariables>(CreateSaleDocument, options);
      }
export type CreateSaleMutationHookResult = ReturnType<typeof useCreateSaleMutation>;
export type CreateSaleMutationResult = Apollo.MutationResult<CreateSaleMutation>;
export type CreateSaleMutationOptions = Apollo.BaseMutationOptions<CreateSaleMutation, CreateSaleMutationVariables>;
export const UpdateSaleDocument = gql`
    mutation UpdateSale($id: ID!, $sale: SaleInput) {
  updateSale(_id: $id, sale: $sale) {
    _id
  }
}
    `;
export type UpdateSaleMutationFn = Apollo.MutationFunction<UpdateSaleMutation, UpdateSaleMutationVariables>;

/**
 * __useUpdateSaleMutation__
 *
 * To run a mutation, you first call `useUpdateSaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSaleMutation, { data, loading, error }] = useUpdateSaleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sale: // value for 'sale'
 *   },
 * });
 */
export function useUpdateSaleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSaleMutation, UpdateSaleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSaleMutation, UpdateSaleMutationVariables>(UpdateSaleDocument, options);
      }
export type UpdateSaleMutationHookResult = ReturnType<typeof useUpdateSaleMutation>;
export type UpdateSaleMutationResult = Apollo.MutationResult<UpdateSaleMutation>;
export type UpdateSaleMutationOptions = Apollo.BaseMutationOptions<UpdateSaleMutation, UpdateSaleMutationVariables>;
export const DeleteSaleDocument = gql`
    mutation DeleteSale($id: ID!) {
  deleteSale(_id: $id) {
    _id
  }
}
    `;
export type DeleteSaleMutationFn = Apollo.MutationFunction<DeleteSaleMutation, DeleteSaleMutationVariables>;

/**
 * __useDeleteSaleMutation__
 *
 * To run a mutation, you first call `useDeleteSaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSaleMutation, { data, loading, error }] = useDeleteSaleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSaleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSaleMutation, DeleteSaleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSaleMutation, DeleteSaleMutationVariables>(DeleteSaleDocument, options);
      }
export type DeleteSaleMutationHookResult = ReturnType<typeof useDeleteSaleMutation>;
export type DeleteSaleMutationResult = Apollo.MutationResult<DeleteSaleMutation>;
export type DeleteSaleMutationOptions = Apollo.BaseMutationOptions<DeleteSaleMutation, DeleteSaleMutationVariables>;