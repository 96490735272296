import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { ProductCard } from "../components/productCard";
import { Product } from "../__generated__/graphql";
import { useQuery, gql } from "@apollo/client";
import header from "../assets/images/banner/b015.jpg";

export const Products = () => {
  const productsQuery = gql(
    `query Products{
      products {
        _id
        name
        description
        image
      }
    }`
  );

  const { loading, error, data } = useQuery(productsQuery);

  const products: Product[] = data?.products || [];

  return (
    <>
      <CssBaseline />
      <Container
        sx={{
          backgroundColor: "white",
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          overflow: "hidden",
        }}
        disableGutters
      >
        <Box
          sx={{
            backgroundImage: `url(${header})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            aspectRatio: "300/200",
            paddingTop: "72px",
            textAlign: "center",
            padding: 0,
          }}
        />
        <Box
          sx={{
            width: "100%",
            minHeight: "calc(100vh - 68.5px)",
            flexGrow: 1,
            paddingBottom: 8,
            paddingX: { xs: 0, sm: 2, md: 3, lg: 4 },
          }}
        >
          <Typography variant="h2" gutterBottom sx={{ paddingTop: 8 }}>
            Våra produkter
          </Typography>
          <Grid container spacing={2} alignItems="stretch">
            {products.map((product, index) => (
              <ProductCard product={product} key={product._id} index={index} />
            ))}
          </Grid>
        </Box>
      </Container>
    </>
  );
};
