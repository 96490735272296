import React from "react";
import { Box } from "@mui/material";
import { CssBaseline } from "@mui/material";

type Props = {};

const Footer = (props: Props) => {
  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          backgroundColor: "primary.main",
          color: "primary.contrastText",
          padding: "1rem",
          textAlign: "center",
        }}
      >
        © 2023 Flavourado AB | All rights reserved
      </Box>
    </>
  );
};

export default Footer;
